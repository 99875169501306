.image img {
  width: 10rem;
  border: 1px solid white;
  border-radius: 17px;
  margin-right: 13px;
}

.content {
  color: black;
}

.content h1 {
  font-size: 45px;
  line-height: 1; /* Changed to 1 for better spacing */
  margin-bottom: 0;
  margin-top: 0;
  width: 273px;
  overflow: hidden;
  white-space: nowrap; 
  padding: 5px 16px;
  border-radius: 5px;
  transition: all 0.3s;
  background: linear-gradient(90deg, #ff0000, #ff7373);
  background-clip: text;
  color: #ffffff; /* Set text color to white or any other visible color */
  user-select: none;
}

@keyframes backgroundChange { 
  0% {
    background: linear-gradient(90deg, #ff0000, #ff7373);
  }
  25% {
    background: linear-gradient(90deg, #00ff00, #73ff73);
  }
  50% {
    background: linear-gradient(90deg, #0000ff, #7373ff);
  }
  75% {
    background: linear-gradient(90deg, #ffff00, #ffff73);
  }
  100% {
    background: linear-gradient(90deg, #ff0000, #ff7373);
  }
} 



.content a {
  border: 1px solid white;
  padding: 5px 16px;
  border-radius: 5px;
  transition: all 0.3s;
  color: white;
  text-decoration: none;
  display: inline-block;
}
.content h1 {
  padding: 5px 16px;
  border-radius: 5px;
  transition: all 0.3s;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.profilea {
  border: none !important;
}

.profilea:hover {
  background: none !important;
}

.content a:hover {
  background-color: grey;
}

.content p {
  font-size: 18px;
  color: white;
  animation-name: rotatepic;
}

.mainfirstsect {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  margin-bottom: 150px;
}

.mainfirst {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .mainfirstsect {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .mainfirst {
    flex-direction: column;
  }
}

@media (max-width: 340px) {
  .content h1 {
    font-size: 35px;
  }
}
