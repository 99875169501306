@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html{
    scroll-behavior: smooth;
}
.contact{
    background: white;
   padding-top: 34px;
   width: 100%;
   margin-bottom: 5px;
}
.contact h1{
    color: black;
    margin-top: 0px;
    margin-left: 6px;
}
.maincontact{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.all h2{
    margin-right: 6px;
    
}

.thirdcontact p{
    margin: 0px;
}
.all a{
    /* background-color: rgb(56, 255, 139); */
    background-color: aliceblue;
    padding: 5px 10px;
    color: black;
    font-size: 34px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all 0.3s linear;
}
.all a img{
    width: 50px;
}
.all a:hover{
    /* background-color: rgb(33, 224, 113); */
    border: 1px solid rgb(170, 170, 170);
    
}
@media(max-width:600px){
    .maincontact{
        flex-direction: column;
    }
    .secondcontact h2{
    font-size: 14px;
 }
}
