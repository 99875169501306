@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');
.maincard {
  display: flex;
  flex-wrap: wrap;
  background: black;
  justify-content: space-between;
}
.mainsection {
  background: white;
}
.card {
  position: relative;
  width: 400px;
 
  margin: 0px 5px;
   font-family: "Bitter", serif;
  background: white;
  margin-bottom: 4px;
  flex-grow: 1;
}
.btn button a {
  color: white;
  text-decoration: none;
}
.imagediv {
  position: relative;
}

.imagediv img {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.imagediv:hover .overlay {
  opacity: 1;
}

.description {
  padding: 20px;
  text-align: center;
}

.btn {
  margin-top: 10px;
}

.btn button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn button:hover {
  background-color: #0056b3;
}
.livelink{
  margin-left: 10px;
}